import React from 'react'

import HeroSingle from '../components/HeroSingle'
import CTABlock from '../components/CTABlock'
import ProductsTable from '../components/ProductsTable'
import PricingTable from '../components/PricingTable'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'

class Compare_products extends React.Component {
  render() {
    const { data, location } = this.props
    return (
      <Layout location={location}>
        <SEO path={location.pathname} frontmatter={data.page.frontmatter} />
        <div className="position-relative">
          <HeroSingle data={data.page} />
          <CTABlock compact data={data.page.frontmatter.cta_block} />
        </div>

        <ProductsTable />
      </Layout>
    )
  }
}

export default Compare_products

export const pricingQuery = graphql`
  query PricingQuery {
    page: markdownRemark(fields: { slug: { eq: "/compare_products/" } }) {
      ...SEO
      ...ctaBlock
      ...ctaBanner
      ...HeroSingle
      id
    }
  }
`
